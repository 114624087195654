import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _295035f4 = () => interopDefault(import('../pages/config.ts' /* webpackChunkName: "pages/config" */))
const _7f2912f7 = () => interopDefault(import('../pages/daily_report.vue' /* webpackChunkName: "pages/daily_report" */))
const _772d8b01 = () => interopDefault(import('../pages/driver_schedule.vue' /* webpackChunkName: "pages/driver_schedule" */))
const _037c8f60 = () => interopDefault(import('../pages/forget_password.vue' /* webpackChunkName: "pages/forget_password" */))
const _967f5c48 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _ec0b12b0 = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/config/",
    component: _295035f4,
    pathToRegexpOptions: {"strict":true},
    name: "config"
  }, {
    path: "/daily_report/",
    component: _7f2912f7,
    pathToRegexpOptions: {"strict":true},
    name: "daily_report"
  }, {
    path: "/driver_schedule/",
    component: _772d8b01,
    pathToRegexpOptions: {"strict":true},
    name: "driver_schedule"
  }, {
    path: "/forget_password/",
    component: _037c8f60,
    pathToRegexpOptions: {"strict":true},
    name: "forget_password"
  }, {
    path: "/login/",
    component: _967f5c48,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/reset_password/",
    component: _ec0b12b0,
    pathToRegexpOptions: {"strict":true},
    name: "reset_password"
  }, {
    path: "/",
    redirect: "/daily_report/",
    name: "index"
  }, {
    path: "*",
    redirect: "/daily_report/",
    name: "notFound"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
